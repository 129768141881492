import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import TestmonialsPresenter from "./testimonials"

const Testimonials = () => {
  const data = useStaticQuery(graphql`
    query {
      gbgSrc: file(relativePath: { eq: "gbg-logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      vaparSrc: file(relativePath: { eq: "vapar-logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      audibleSrc: file(relativePath: { eq: "audible-logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      zadaraSrc: file(relativePath: { eq: "zadara-logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      wmSrc: file(relativePath: { eq: "wm-logo.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      zenSrc: file(relativePath: { eq: "zen-logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      wocaSrc: file(relativePath: { eq: "woca-logo.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      salamaSrc: file(relativePath: { eq: "salama-logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      avertroSrc: file(relativePath: { eq: "avertro-logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      iarcSrc: file(relativePath: { eq: "iarc-logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      atcSrc: file(relativePath: { eq: "atc-logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      recoSrc: file(relativePath: { eq: "reco-logo.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      happyBoxSrc: file(relativePath: { eq: "happyBoxes-logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      herSpaceSrc: file(relativePath: { eq: "herSpace-logo.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pinkESrc: file(relativePath: { eq: "pinkE-logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hotelESrc: file(relativePath: { eq: "hotelE-logo.jpeg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      allTSrc: file(relativePath: { eq: "allT-logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      asaSrc: file(relativePath: { eq: "asa-logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      nappySrc: file(relativePath: { eq: "nappy-logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bridgeItSrc: file(relativePath: { eq: "bridge-it-logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      climarteSrc: file(relativePath: { eq: "climarte-logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mountainSrc: file(relativePath: { eq: "mountain-logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const TESTIMONIALS = [
    {
      name: "Geoffrey Smith",
      title: "CEO",
      company: "Australian Spatial Analytics",
      logoPath: data.asaSrc,
      comment:
        "Mylan and the Vu team continue to make incredible impact for our Australian Spatial Analytics. As a scaling company, Mylan immediately understood the right stories for us to get in front of the right decision-makers at the right time. PR and Comms can be murky for me but Mylan’s easy going style, transparency and advice made the campaigns easy for us, and it’s actually fun! Thanks to the team at Vu, our organisation is now commanding the space we occupy. We are so lucky to have found them, not only because they make it easy for the world to find us, but because they are awesome humans to work with.",
    },
    {
      name: "Ian Yip",
      title: "CEO",
      company: "Avertro",
      logoPath: data.avertroSrc,
      comment:
        "I've worked with many people through my career; Mylan is one of the most intelligent, honest, effective people I've ever met. When we needed a business partner to help expand our PR and communications efforts, we chose Mylan. The best thing about her team is that they function seamlessly as an extension of Avertro. She understands our industry, and knows exactly how we like to collaborate. Mylan actively ensures we can consistently reach our audience effectively and in an engaging manner. At Avertro, we've been working with Vu Consulting for some time. Our ability to garner coverage in the public domain is tangible evidence that our partnership with Mylan and her team works.",
    },
    {
      name: "Samantha Payne",
      title: "CEO",
      company: "Pink Elephants Support Network",
      logoPath: data.pinkESrc,
      comment:
        "Our challenge as a small, under-resourced charity is ensuring that the people who need us the most, are able to connect with us. Vu Consulting have proven time and time again how powerful PR can be to increase our brands exposure and assist us in amplifying our voice. Everyone we have worked with at Vu Consulting is kind, compassionate, extremely professional and they get the job done. They have not only secured a wide range of media exposure for our national charity, but worked with us on communications strategies, advocacy campaigns, provided media training to our staff, attended events with us and most importantly, they have always gone above and beyond to ensure we get closer to achieving our mission - to ensure that no one walks the journey of pregnancy loss alone.",
    },
    {
      name: "Brenda Gaddi",
      title: "Women of Colour (WoCA) Australia",
      company: "Founder and Managing Director",
      logoPath: data.wocaSrc,
      comment:
        "Vu Consulting was largely instrumental in helping Women of Colour Australia (WoCA) achieve widespread media coverage, specifically within top tier media outlets for its inaugural Women of Colour in the Workplace Survey report. We are deeply grateful for their continued PR support and service. A very special mention to Caroline La Rose, she is WoC power personified. Much love and respect and appreciation to our C!",
    },
    {
      name: "Deborah Hart",
      title: "Co-Chair",
      company: "CLIMARTE",
      logoPath: data.climarteSrc,
      comment:
        "On every level, our experience working with Louise Morrisey and Mylan Vu from Vu Consulting has been exceptional. At every stage of our programme’s development, CLIMARTE’s unique work intersecting art, science, culture and community was thoroughly and deeply considered and encouraged. Through a collaborative, informative and accessible process, communication material, strategies and actions were designed to maximise impact. We are so appreciative for Vu Consulting’s genuine interest in supporting and advancing our work. They are wonderful, highly professional people, and we couldn’t recommend their services more.",
    },
    {
      name: "Amanda Siqueira",
      title: "CEO and Co-Founder",
      company: "VAPAR",
      logoPath: data.vaparSrc,
      comment:
        "An absolute stand out! Mylan smashed our PR objectives in an incredibly specialised niche B2B space. She was very quick to align with our business objectives, very responsive and went above and beyond to help us out when it was clear that media deadlines were at risk. Highly recommend her work. We are certainly happy (and repeat) clients of hers!",
    },
    {
      name: "Marjorie Tenchavez",
      title: "Founder",
      company: "Welcome Merchant",
      logoPath: data.wmSrc,
      comment:
        "Mylan and the rest of her team at Vu Consulting have been a wonderful support to the social enterprise I've founded, Welcome Merchant. We started working with them less than a month ago and we've already seen some wonderful results - at least 4 media articles about our upcoming events. Mylan's very professional, easy to communicate with and is genuinely a good person. Thank you Mylan!",
    },
    {
      name: "Bianca Stern",
      title: "General Manager",
      company: "All Things Equal",
      logoPath: data.allTSrc,
      comment:
        "Mylan and her team were nothing short of brilliant. They were efficient, enthusiastic, supportive and empowering. All Things Equal feels incredibly grateful to Vu Consulting!",
    },
    {
      name: "Sarah Witty",
      title: "CEO",
      company: "The Nappy Collective",
      logoPath: data.nappySrc,
      comment:
        "Louise was a valued addition to The Nappy Collective team. As a volunteer-based organisation, The Nappy Collective was experiencing extreme challenges as we attempted to rebuild after the effects of COVID 19 lockdowns. Louise put up her hand to help at a critical time as we worked towards return to business with our first 2-week collective campaign for two years. Louise provided advice and training to our inexperienced team making it easy for us to deal with media opportunities as they arose. Louise’s dedication and professionalism ensured numerous opportunities were obtained, which were well over our expectations making our July Nappy Collective campaign a huge success. The insights she provided to The Nappy Collective have set us up for future success. I am more than hopeful that we can work together again in the future, and I would recommend her services to anyone, we absolutely couldn’t have done it without her.",
    },
    {
      name: "Amy Nguyen",
      title: "Founder",
      company: "Zen Tea Lounge Foundation",
      logoPath: data.zenSrc,
      comment:
        "As a Domestic Violence charity, we have greatly benefited from working with Vu Consulting. The professional team took the time to understand deeply what we wanted to achieve and worked with our organisation to develop a campaign that exceeded our expectations. After only a short period of working together, through their unique approach Mylan and her team instantly won our trust and respect. They're professional, quick to respond to the news and have in-depth knowledge in the media and charity spaces. Their positive working relationship made it an impressive experience. Through working with the team, Zen Tea Lounge Foundation received multiple pieces of coverage in the media, including articles and podcasts across the education, not-for-profit, business and parenting spaces. We would not hesitate to recommend Vu Consulting.",
    },
    {
      name: "Hamed Allahyari",
      title: "Owner",
      company: "Sunshine Cafe and SalamaTea House",
      logoPath: data.salamaSrc,
      comment:
        "I'm so happy that I have a very strong team who are supporting my small social enterprise now. To be honest, I was lost and I couldn't look after SalamaTea House marketing anymore because I had too much on my plate. Since Amy and the team have been supporting me it has changed a lot and I feel so much better and can focus more on other things at the cafe. The SalamaTea House team and I are so lucky to have the Vu Consulting team to support us. I am looking forward to meeting you all in person and if any time you come to Melbourne you should definitely come to our cafe so I could host you with our delicious food.",
    },
    {
      name: "Priscilla Brice",
      title: "Managing Director",
      company: "All Together Now",
      logoPath: data.atcSrc,
      comment:
        "All Together Now worked with Vu Consulting to launch a research report, and we were so impressed with their work! Vu picked up the nuances of the issue we work on very quickly, and drafted a media release and other PR material that required almost no reworking from us. Vu also successfully landed 70+ media mentions within 24 hours of the media release. They are very proactive and easy to work with, very highly recommended!",
    },
    {
      name: "Danling Xiao and Anett Petrovics",
      title: "Co-Founders",
      company: "ReCo",
      logoPath: data.recoSrc,
      comment:
        "We were on the front covers of the popular local papers Wentworth Courier, Mosman Daily and North Shore Times. As a hyperlocal business, this kind of opportunity really helps our startup break out to our local audience - all thanks to Mylan and her team's amazing work! Vu Consulting is as it says, cut-through PR. Mylan is strategic and focused. She was able to take in all the information we have and provide strategic direction and execution to present us in the media. In the fast-paced news world, the team is well aware of news and contents that are relevant to us and provide opportunities to shine a spotlight on what we do, but also help us be a voice for environmental issues, gender equality and diversity. We enjoyed working with Mylan and her team a lot, and look forward to working with them again in the near future!",
    },
    {
      name: "Roxan Fabiano",
      title: "Executive Officer",
      company: "HerSpace",
      logoPath: data.herSpaceSrc,
      comment:
        "Mylan and her team have been integral to the work of HerSpace, supporting the recovery of survivors of modern slavery in Australia. Mylan’s expert and targeted PR strategy skyrocketed HerSpace’s profile in the media nationally, with multiple media publications across prominent papers and platforms. HerSpace went from a small presence in Melbourne, Victoria, to an audience of millions nationally. She made the entire process seamless and straightforward for us. She was efficient and strategic – she opened doors. Generous with her knowledge, she equipped and empowered me to confidently manage future PR strategies. Her work was integral in helping HerSpace gain support and momentum behind our strategic plan. Mylan has helped give a voice in this nation to survivors of modern slavery. I highly recommend Vu Consulting to any organisation seeking maximum results.",
    },
    {
      name: "Andrea Comastri",
      title: "Co-founder and CEO",
      company: "Hotel Etico",
      logoPath: data.hotelESrc,
      comment:
        "Mylan and Ricki have been amazing! Very professional, prompt, responsive and extremely proactive in identifying appropriate opportunities for Hotel Etico. Mylan’s ability to understand the problem and opportunity and connect it to the right channel, by sharpening and targeting the message have been very valuable, resulting in articles, features, quotes, opportunistic media comments and many resources developed specifically for us or shared with us. I have personally appreciated the easy access to Mylan and her can do approach. Thank you for the opportunity!",
    },
    {
      name: "Emma Sullings",
      title: "Founder",
      company: "The Happy Boxes Project",
      logoPath: data.happyBoxSrc,
      comment:
        "We have been extremely fortunate to have Louise and Vu Consultancy help us make an incredible splash in the media this year. There is no way we would have been able to get the attention and coverage we did without Louise's help. Throughout the program, we secured eight pieces of standalone media coverage, across television, radio, print and online. I can not speak more highly of the support we received. Through Louise's mentoring the team has gained important skills and knowledge to engage with the media with confidence and determination. The attainment of such skills will continue to benefit our cause and allow us to achieve future targets, and for this we are incredibly grateful. Louise has been one of the most professional people I have had the pleasure of working with. She led the program with respect, integrity and warmth. Her genuine commitment to our cause allowed myself and the team to develop trust and rapport with her immediately which made the working relationship seamless and effective. In the past when we have engaged with pro bono projects we have not always felt highly valued or that our project is a high priority. With Vu Consulting we knew we had their undivided attention, we felt valued by their team and we received the same high level support that a paying client would receive. The work Louise did for us really can't be quantified, as the connections and opportunities that will arise from the media attention is yet to fully reveal itself. The Happy Boxes Project would jump at the opportunity to work with Louise and Vu Consulting again and will consistently recommend them to anyone in need of PR support. We honestly cannot thank Louise and Vu Consulting enough for what they have generously gifted us.",
    },
    {
      name: "Carla Raynes",
      title: "Founder and CEO",
      company: "Bridge It",
      logoPath: data.bridgeItSrc,
      comment:
        "A tiny org like Bridge It is not in a position to be able to afford high quality PR and comms support so it has been an absolute treat to get this assistance probono! I have thoroughly enjoyed working with you both, you are so compassionate and skilled at what you do. I really appreciated how you created a detailed plan outlining the deliverables of the support, but were happy to change direction when Bridge It’s priorities changed. I am so happy with the comms plan that you created.",
    },
    {
      name: "Laura Myer",
      title: "Director and Co-founder",
      company: "Mountain Track",
      logoPath: data.mountainSrc,
      comment:
        "It has been an absolute pleasure working with Vu Consulting on Mountain Track's Impact Report and Case for Support Documents. What they have created for us here (pro-bono), will allow us to share our story and the impact we are having with a wide range of current and potential stakeholders, raise more funds and support more young people. Thank you Vu Consulting, we look forward to working with you again.",
    },
  ]

  return <TestmonialsPresenter testimonials={TESTIMONIALS} />
}

export default Testimonials
